<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box land-info">
            <div class="land-info__header">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <el-carousel indicator-position="outside">
                            <el-carousel-item v-for="(item, index) in landPics" :key="index">
                                <img :src="item+'?w=600'" alt="" style="width: 100%; height: 100%" />
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col :span="14">
                        <h3 class="land-info__title">{{ landInfo.title }}</h3>
                        <el-button v-if="landInfo.auditStatus == 2" type="text" :icon="haveFavorite?'el-icon-star-on' : 'el-icon-star-off'" class="fav-btn" @click="addFavorite">{{ haveFavorite?'取消收藏':'收藏' }}</el-button>
                        <el-button v-if="landInfo.auditStatus == 3" type="danger" class="fav-btn" style="font-size: 18px;color: whitesmoke" >审核不通过</el-button>
                        <el-button v-if="landInfo.auditStatus == 1" type="danger" class="fav-btn" style="font-size: 18px;color: whitesmoke" >未审核</el-button>
                        <p class="land_info__item">
                            <span>
                                <i class="ti ti-map-pin"></i>
                                四川省/德阳市/{{ findLabel(land_area,landInfo.regionId) }}
                            </span>
                            <span>
                                <i class="ti ti-clock-hour-3"></i>
                                {{ landInfo.createTime }}
                            </span>
                        </p>
                        <div class="price-panel" v-if="!landInfo.agreementTransaction  && landInfo.landSupplyType!=5">
                            <span class="label">价格：</span>
                            <span class="price-text" v-if="landInfo.price > 0">￥{{ landInfo.price }}万元</span>
                            <span class="price-text" v-else >面议</span>
                        </div>
                      <div class="price-panel" v-if="landInfo.agreementTransaction && landInfo.agreementTransaction.transactionStatus === 'finish'">
                            <span class="label">成交价格：</span>
                            <span class="price-text" >￥{{ landInfo.agreementTransaction.amount }}万元</span>
                        </div>



                        <p class="land_info__item">
                            <span>
                                所属行政区： {{ findLabel(land_area,landInfo.regionId) }}<!--{{ landInfo.landPosition }}-->
                            </span>
                        </p>
                        <p class="land_info__item">
                            <span>
                             土地面积： {{landInfo.landSize}}平方米
                            </span>
                        </p>
                        <p class="land_info__item">
                            <span>
                             土地用途： {{ findLabel(demand_purpose,landInfo.landPurpose) }}

                            {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}
                            </span>
                        </p>
                        <p class="land_info__item">
                            <span>
                             <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                                交易方式： {{ findLabel(transaction_way,landInfo.transactionWay) }}
                            </span>
                        </p>

                      <p class="land_info__item">
                            <span>
                             <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                                联系方式：<el-tooltip v-if="isLogin"  :content="landInfo.phoneNumber" placement="top" effect="light">
                             <el-link>{{landInfo.phoneNumber}} </el-link>
                        </el-tooltip>

                                <el-tooltip v-if="!isLogin" content="点击登录 " placement="top" effect="light">
                             <el-link  >登录查看 </el-link>
                        </el-tooltip>
                            </span>
                      </p>
                      <p class="land_info__item">
                            <span>
                             <!-- 属权单位： {{ landInfo.ownerOrg }} -->
                        <RouterLink target="_blank" to="/services"  >委托交易</RouterLink>&nbsp;&nbsp;&nbsp;
                        <RouterLink target="_blank" to="/service-guide?id=1666269628714201127"  >合同服务</RouterLink>
                            </span>
                      </p>


                    </el-col>
                </el-row>
            </div>

            <div class="land-info__detail">
                <div class="land-info__detail__nav">
                    <a @click="scrollInto('info')" >土地信息</a>
                    <a  @click="scrollInto('img')" >土地图片</a>
                    <a  @click="scrollInto('map')" >土地地图</a>
                    <a v-if="landInfo.vr" @click="gotoLink(landInfo.vr)" >VR</a>
                </div>
                <div id="info">
                    <el-descriptions title="土地信息" :column="2" border>
                      <el-descriptions-item label="土地用途" label-align="right" align="center" width="150px">{{ findLabel(demand_purpose,landInfo.landPurpose) }}
                        {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}
                      </el-descriptions-item>

                      <el-descriptions-item label="交易方式" label-align="right" align="center" width="150px">{{ findLabel(transaction_way,landInfo.transactionWay) }}</el-descriptions-item>
                      <el-descriptions-item v-if="false" label="土地用途2" label-align="right" align="center" width="150px">{{ findLabel(demand_purpose,landInfo.landPurpose2) }} - {{getLandPurposeItemName(landInfo.landPurpose2, landInfo.subLandPurpose2)}}</el-descriptions-item>
                      <el-descriptions-item label="所属行政区" label-align="right" align="center">{{ findLabel(land_area,landInfo.regionId) }}</el-descriptions-item>
                        <el-descriptions-item label="土地性质" label-align="right" align="center">{{ findLabel(land_type,landInfo.ownerType) }}</el-descriptions-item>
                      <!-- <el-descriptions-item label="权属单位" label-align="right" align="center">{{landInfo.ownerOrg}}</el-descriptions-item>-->
                      <el-descriptions-item v-if="landInfo.landSupplyType!=5" label="权证类型" label-align="right" align="center">{{findLabel(land_license_type,landInfo.licenseNoType)}}</el-descriptions-item>
<!--                      <el-descriptions-item label="权证编号" label-align="right" align="center">{{landInfo.licenseNo}}</el-descriptions-item>-->
                      <el-descriptions-item v-if="landInfo.landSupplyType!=5" label="使用权截止日期" label-align="right" align="center">{{landInfo.landDeadLine}}</el-descriptions-item>
                      <el-descriptions-item label="土地面积" label-align="right" align="center">{{landInfo.landSize}}平方米</el-descriptions-item>
                      <el-descriptions-item label="容积率" label-align="right" align="center">{{landInfo.volumeRate}}</el-descriptions-item>
                     <!-- <el-descriptions-item label="地上建筑物类型" label-align="right" align="center"></el-descriptions-item>-->
                        <el-descriptions-item label="土地位置" label-align="right" align="center">{{landInfo.landPosition}}</el-descriptions-item>
                        <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑类型" label-align="right" align="center">{{landInfo.buildingType}}</el-descriptions-item>
                        <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑面积" label-align="right" align="center">{{landInfo.buildingSize}}平方米</el-descriptions-item>
                        <el-descriptions-item label="" label-align="right" align="center">  </el-descriptions-item>
                        <el-descriptions-item label="土地描述" span="3" ><div style="max-width: 500px" v-html="landInfo.desc"></div></el-descriptions-item>
                    </el-descriptions>
                </div>
                <div id="img">
                    <el-descriptions title="土地图片" :column="1"></el-descriptions>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="(item,index) in landPics" :key="index">
                            <img :src="item+'?w=600'" width="100%"/>
                        </el-col>
                    </el-row>
                </div>
                <div id="map">
                    <el-descriptions title="土地地图" :column="1"></el-descriptions>
                    <Map :landInfo="landInfo"/>
                </div>
            </div>

        </div>
        <footer2 />
    </div>
</template>
<script lang="ts">
import LandHeader from "@/components/nav";
import footer2 from "components/footer.vue";
import Map from "@/components/TMap.vue";
import CommonService from "../service/CommonService";
import { useDict,findLabel } from "@/hooks/dict";
import { ref,nextTick } from "vue";
import '@/assets/css/land-info.scss'
import {RouterLink} from "vue-router";

export default {
    name: "LandInfo",
    components: {
      RouterLink,
        LandHeader,
        footer2,
        Map
    },
    data() {
        return {
            mapMode:'地图',
            haveFavorite: false,
            clickState: false,
            landInfo: {
                id: "",
                title: "",
                landPurpose2: "",
              agreementTransaction: {
                amount:''
              },
              subLandPurpose: "",
              subLandPurpose2: "",
                landPics: '',
                desc: '',
                regionId: '',
                createTime: '',
                price: '',
                landPurpose: '',
              auditStatus: '',
                transactionWay: '',
                ownerType: '',
                ownerOrg: '',
                licenseNoType: '',
              buildingSize: '',
                licenseNo: '',
              phoneNumber: '',
                landSize: '',
                landDeadLine: '',
                landPosition: '',
              anonymous: 0,
                volumeRate: '',
              landType: '',
                geoInfo:[]
            },
        };
    },
    computed: {
        haveMap() {
            return this.landInfo.geoInfo && this.landInfo.geoInfo.length > 0;
        },
        landPics() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",") : [];
        },
        land_purpose() {
            return this.getDict("land_purpose");
        },
         demand_purpose() {
          // console.log(this.getDict("demand_purpose"))
            return this.getDict("demand_purpose");
        },
        transaction_way() {
            return this.getDict("transaction_way");
        },
        land_area() {
            return this.getDict("land_area");
        },
        land_owner_type() {
            return this.getDict("land_owner_type");
        },
        land_license_type() {
            return this.getDict("land_license_type");
        },
      land_type() {
        return this.getDict("land_type");
      }, isLogin() {
        return CommonService.isLogin();
      }

      ,
    },
    mounted() {
        this.loadLandInfo();
        this.checkFavorite();
    },
    methods: {
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
        scrollInto(id) {
          document.getElementById(id).scrollIntoView();
        },
        gotoLink(url) {
          this.$router.push("/link?url="+url);
        },
        addFavorite() {
            if(!CommonService.isLogin()){
                this.$message.error("请先登录");
                return;
            }
            if(!this.clickState)
            {
              this.clickState=true;
            if(this.haveFavorite){
                CommonService.delFavorite(this.landInfo.id,"supply").then((res) => {
                  this.clickState=false;
                    if (res.code === 0) {
                        this.haveFavorite = false;
                        this.$message.success("取消收藏成功");
                    }
                });
            }else{

                CommonService.addFavorite(this.landInfo.id,"supply").then((res) => {
                  this.clickState=false;
                    if (res.code === 0) {
                        this.haveFavorite = true;
                        this.$message.success("收藏成功");
                    }
                });
            }
            }else {
            //  this.$message.error("点击过多！");
            }

        },
        checkFavorite() {
            if(!CommonService.isLogin()){
                return;
            }

            CommonService.checkFavorite(this.$route.params.id,"supply").then((res) => {
                if (res.code === 0) {
                    this.haveFavorite = res.data;
                }
            });
        },
        loadLandInfo() {
            CommonService.getLandDetail(this.$route.params.id).then((res) => {
              // console.log(res)
                if (res.code === 0) {
                  if(res.data.anonymous==1)
                  {
                    res.data.phoneNumber="0838-2502185";
                  }else {
                    res.data.phoneNumber= res.data.contact+"："+res.data.phoneNumber;
                  }
                    Object.assign(this.landInfo,res.data);  
                }
            });
        },
        getDict(dictName) {
            return useDict(dictName)[dictName].value;
        },
        findLabel,
    },
};  
</script>